import { Component, inject, Input, OnInit } from "@angular/core";
import { LanguageService } from "@app/shared/services/language.service";
import { Constants } from "@core/constants";
import { WINDOW } from "@ng-web-apis/common";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { ModalService } from "@shared/services/modal.service";
import { PlanStateService } from "@shared/services/plan-state.service";
import { NgClass } from "@angular/common";
import { IonButton, IonLabel } from "@ionic/angular/standalone";

@Component({
  selector: "pa-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.scss"],
  standalone: true,
  imports: [NgClass, TranslateModule, IonButton, IonLabel],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() theme: "dark" | "light" = "light";
  language!: string;
  languageLabel!: string;

  private modalService = inject(ModalService);
  private planStateService = inject(PlanStateService);
  private translateService = inject(TranslateService);
  private languageService = inject(LanguageService);
  private window = inject<Window>(WINDOW);

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.languageLabel = this.language === Constants.LANGUAGE_EN ? Constants.LANGUAGE_NL : Constants.LANGUAGE_EN;
  }

  onLanguageSelect(): void {
    if (this.planStateService.isPlanModified()) {
      this.modalService
        .handleConfirmModal("general.confirm.confirmTitle", "general.confirm.confirmQuestion")
        .subscribe((result) => {
          result && this.toggleLanguage();
        });
    } else {
      this.toggleLanguage();
    }
  }

  toggleLanguage(): void {
    this.language = this.languageLabel;
    this.languageLabel = this.language === Constants.LANGUAGE_EN ? Constants.LANGUAGE_NL : Constants.LANGUAGE_EN;

    this.translateService.use(this.language).subscribe(() => {
      this.languageService.setLanguage(this.language);
      this.window.location.reload();
    });
  }
}
